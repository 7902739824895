body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
}

h1,
h2,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

h2 {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 1rem;
}

h4 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h5 {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

p {
  font-size: 1rem;
  line-height: 1.4;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

hr {
  border-bottom: none;
  border-top: 1px solid #000;
  margin: 0;
}

pre {
  white-space: pre-wrap;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

fieldset {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  padding: 1rem;
}

form {
  max-width: 100%;
  @media (min-width: 768px) {
    max-width: 400px;
  }
}

input {
  border-radius: 4px;
  border: 1px solid #000;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
  padding: 0.5rem;
}

legend {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 0.5rem;
}

dl {
  margin: 0.5rem 0;
}

code {
  background: #ddd;
  border-radius: 4px;
  font-family: monospace;
  padding: 0.25rem;
}
